import Notifications from "./Notifications/Notifications";
import Languages from "./Languages";
import { Phone } from "@phosphor-icons/react";
import { useSelector } from "react-redux";

const Topbar = ({ setIsOpen }: any) => {
  const user = useSelector((state: any) => state.user);

  return (
    <div className="d-flex align-items-center">
      <Notifications />

      {user.tipo !== "E" ? (
        <div className="btn btn-slim mr-2">
          <Phone
            id="kt_show_contacts_toggle"
            weight="bold"
            size={22}
            onClick={() => {
              setIsOpen(true);
            }}
            className="pointer"
          />
        </div>
      ) : null}

      <Languages />
    </div>
  );
};

export { Topbar };
