import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import classes from "./Navbar.module.css";
import Button from "../../../../../components/UI/Button/Button";
import { Dropdown } from "react-bootstrap";
import EmployeeImage from "../../../../../components/UI/EmployeeImage/EmployeeImage";
import { selfEvaluationActions } from "../../../../../setup/store/selfevaluation";
import { DoorOpen } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selfEvaluation = useSelector((state: any) => state.self_evaluation);

  function getFullName() {
    try {
      const names = selfEvaluation.nome.trim().split(" ");
      let firstName = names[0];
      firstName =
        firstName.substring(0, 1).toUpperCase() +
        firstName.substring(1).toLowerCase();
      if (names.length > 1) {
        let lastname = names[names.length - 1];
        lastname =
          lastname.substring(0, 1).toUpperCase() +
          lastname.substring(1).toLowerCase();
        return firstName + " " + lastname;
      } else {
        return firstName;
      }
    } catch {
      return selfEvaluation.nome;
    }
  }

  return (
    <>
      <div className="col-12 d-flex align-items-center justify-content-between p-5">
        <div className="d-flex align-items-center justify-content-between col-5 col-lg-3">
          <img
            src="/media/logo_rpd/rpeopleverde.png"
            height="30px"
            className={"pointer"}
            onClick={() => {
              navigate("/avaliacao");
            }}
          />
          <NavLink
            to="/avaliacao"
            className={({ isActive }) =>
              isActive ? "btn btn-secondary" : "btn btn-tertiary"
            }
            end
          >
            Visão Geral
          </NavLink>
        </div>
        <div>
          <Dropdown>
            <Dropdown.Toggle className="d-flex align-items-center btn-tertiary btn-slim text-black">
              <EmployeeImage
                id={selfEvaluation.id_colaborador}
                size={25}
                space="mr-3"
                accessToken={selfEvaluation.accessToken}
              />
              <span className="mr-2">{getFullName()}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="px-5 py-3">
              <Dropdown.Item
                className="btn btn-tertiary btn-slim text-black fw-bold"
                onClick={() => {
                  dispatch(selfEvaluationActions.logout());
                }}
              >
                <DoorOpen size={20} className="mr-1" />{" "}
                <span>Terminar Sessão</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <label className="separator col-12 px-5 mb-10" />
    </>
  );
};

export default Navbar;
