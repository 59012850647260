import clsx from "clsx";
import { X } from "@phosphor-icons/react";

type Props = {
  id?: any;
  title?: string;
  placeholder?: string;
  state?: any;
  stateUpdate: any;
  param?: any;
  space?: any;
  order?: any;
  size?: any;
  icon?: any;
  required?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  rows?: number;
  cols?: number;
};

const TextArea: React.FC<Props> = ({
  id,
  title,
  placeholder,
  required,
  state,
  stateUpdate,
  param,
  space,
  disabled,
  size,
  icon,
  clearable,
  rows,
}) => {
  return (
    <div
      className={clsx(
        "form-input d-flex",
        space ? space : "mb-2",
        size && size,
        icon || clearable
          ? "align-items-center flex-wrap"
          : "flex-column justify-content-center",
        disabled && "disabled"
      )}
    >
      {title ? (
        <label htmlFor={id} className={clsx(required && "required")}>
          {title}
        </label>
      ) : null}
      {icon ? <div>{icon}</div> : null}
      <textarea
        id={id}
        required={required ? required : false}
        value={state || ""}
        placeholder={placeholder ? placeholder : ""}
        onChange={(e) => {
          if (param != null) {
            stateUpdate(e.target.value, param);
          } else {
            stateUpdate(e.target.value);
          }
        }}
        disabled={disabled}
        className={clsx(icon && "ml-1")}
        rows={rows || 3}
      />
      {clearable && state ? (
        <div className="clear">
          <X
            size={14}
            weight="bold"
            onClick={() => {
              stateUpdate("");
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default TextArea;
