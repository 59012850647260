import axios from "../axios.config";

export function getAllUsers(
  accessToken: string,
  search: string,
  order?: any,
  offset?: any,
  active?: string,
  type?: any,
  sector?: any
) {
  return axios.get(
    `/users?search=${search}${offset ? `&offset=${offset}` : ""}${
      order ? `&order=${order}` : ""
    }${active ? `&active=${active}` : ""}${type ? `&type=${type}` : ""}${
      sector ? `&sector=${sector}` : ""
    }`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getAllSectors(accessToken: string) {
  return axios.get("/rh_ad/sectors", {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getAllGe(accessToken: string, order?: any) {
  return axios.get("/managers/ge", {
    params: { order: order },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getAllGv(accessToken: string, order?: any) {
  return axios.get("/managers/gv", {
    params: {
      order: order,
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getAllInstructors(accessToken: string, active?: any) {
  return axios.get(
    `/trainings/instructors${active ? `?active=${active}` : ""}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getAllAgents(
  accessToken: string,
  order?: any,
  bloqueado?: string
) {
  return axios.get("/agents", {
    params: {
      order: order,
      bloqueado: bloqueado,
    },
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function createUser(accessToken: string, user: any) {
  return axios.post("/users", user, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getUserById(accessToken: string, userId: any) {
  return axios.get(`/users/${userId}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateUserById(accessToken: string, user: any, userId: any) {
  return axios.put(`/users/${userId}`, user, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getNotifications(accessToken: string) {
  return axios.get(`/users/notificacoes`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateNotifications(accessToken: string, data: any) {
  return axios.patch(`/users/notificacoes/`, data, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}
