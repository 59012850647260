import clsx from "clsx";
import { GetIcon } from "../../../setup/utilities/icons";

type Props = {
  id?: any;
  title?: string | JSX.Element;
  style?: string;
  submit?: boolean;
  form?: string;
  iconPos?: "left" | "right";
  icon?: string;
  iconColor?: string;
  space?: string;
  onClick?: any;
  disabled?: boolean;
  iconSize?: number;
};

const Button: React.FC<Props> = ({
  id,
  title,
  style,
  submit,
  form,
  iconPos,
  icon,
  iconColor,
  space,
  onClick,
  disabled,
  iconSize,
}) => {
  const JSXIcon = icon ? GetIcon(icon) || null : null;

  return (
    <button
      id={id ? id : null}
      className={clsx(
        style ? style : "btn btn-primary",
        icon && "d-flex align-items-center",
        space && space
      )}
      type={submit ? "submit" : "button"}
      onClick={() => {
        if (onClick) onClick();
      }}
      disabled={disabled}
      form={form}
    >
      {iconPos === "right" ? title : null}
      {JSXIcon ? (
        <JSXIcon
          size={iconSize ? iconSize : 20}
          weight="bold"
          className={clsx(title && `${iconPos === "right" ? "ml-2" : "mr-2"}`)}
          color={iconColor || undefined}
        />
      ) : null}
      {iconPos !== "right" ? title : null}
    </button>
  );
};

export default Button;
