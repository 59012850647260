import { Suspense, useContext, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMenus } from "./setup/store/menus";
import Loading from "./pages/Loading/Loading";
import { LayoutContext } from "./components/_metronic/layout/core";
import PublicRoutes from "./routes/Public";
import { verifyToken } from "./axios/requests/auth";
import { userActions } from "./setup/store/user";
import axios from "axios";
import moment from "moment";

const App: React.FC = () => {
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.user);
  const menus = useSelector((state: any) => state.menus.menus);

  const layoutProvider = useContext(LayoutContext);

  let storedToken = useMemo(() => localStorage.getItem("token"), []);

  function saveLocal(value: boolean) {
    dispatch(userActions.updateLocal({ local: value }));
  }

  useEffect(() => {
    if (user.tipo === "I") {
      saveLocal(true);
    } else {
      axios
        .get(`https://srvdev01.riopele.pt?timestamp=${moment()}`)
        .then(() => {
          saveLocal(true);
        })
        .catch(() => {
          saveLocal(false);
        });
    }
  }, [user.tipo]);

  useEffect(() => {
    if (!storedToken) {
      layoutProvider.setAppLoading(false);
    } else {
      dispatch(userActions.login({ accessToken: storedToken }));
    }
  }, [storedToken]);
  useEffect(() => {
    if (user.accessToken && user.id_user) {
      dispatch(getMenus(user.accessToken, user.id_user));
    } else if (user.accessToken && !user.id_user) {
      verifyToken(user.accessToken)
        .then((res) => {
          dispatch(userActions.saveUser(res.data));
        })
        .catch((err) => {
          localStorage.removeItem("token");
          layoutProvider.setAppLoading(false);
        });
    }
  }, [user.accessToken, user.id_user]);

  useEffect(() => {
    if (menus) {
      layoutProvider.setAppLoading(false);
    }
  }, [menus]);

  return (
    <Suspense fallback={<div></div>}>
      {!layoutProvider.loading ? <PublicRoutes /> : <Loading />}
    </Suspense>
  );
};

export default App;
