import { FC, useEffect, useRef } from "react";
import clsx from "clsx";
import { useLayout } from "../../core";
import { toAbsoluteUrl } from "../../../helpers";
import { AsideMenu } from "./AsideMenu";
import { Minus, Plus, SignOut } from "@phosphor-icons/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../../../../setup/store/user";
import {
  DrawerComponent,
  ScrollComponent,
  ToggleComponent,
} from "../../../assets/ts/components";
import { useIntl } from "react-intl";

const AsideDefault: FC = () => {
  const user = useSelector((state: any) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const { classes } = useLayout();
  const { pathname } = useLocation();

  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
      ScrollComponent.reinitialization();
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div
      id="kt_aside"
      className={clsx("aside", classes.aside.join(" "))}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      {/* begin::Brand */}
      <div className="aside-logo flex-column-auto" id="kt_aside_logo">
        {/* begin::Logo */}
        <img
          alt="Logo"
          src={toAbsoluteUrl("/media/logo_rpd/Riopele_Digital_dark.svg")}
          height="25px"
          onClick={() => {
            navigate("/start");
          }}
          className="logo"
        />
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        <div
          id="kt_aside_toggle"
          className="btn btn-icon w-auto btn-active-color-primary aside-toggle p-0"
          data-kt-toggle="true"
          data-kt-toggle-state="active"
          data-kt-toggle-target="body"
          data-kt-toggle-name="aside-minimize"
        >
          <Plus size={20} weight="bold" className="button-minimize" />
          <Minus size={20} weight="bold" className="button-expand" />
        </div>
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className="aside-menu flex-column-fluid px-5">
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div className="aside-footer flex-column-auto p-5" id="kt_aside_footer">
        <label className="separator mb-3" />
        <div
          className={clsx("menu-item")}
          onClick={() => {
            dispatch(logoutAction(user.accessToken));
          }}
        >
          <div className={clsx("menu-link without-sub")}>
            <SignOut weight="bold" size={20} />
            <span className="menu-title">
              {intl.formatMessage({ id: "HEADERUSERMENU.LOGOUT" })}
            </span>
          </div>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  );
};

export { AsideDefault };
