import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../../components/UI/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import EmployeeImage from "../../../components/UI/EmployeeImage/EmployeeImage";
import { Dropdown } from "react-bootstrap";
import {
  DoorOpen,
  Hamburger,
  List,
  MagnifyingGlass,
  User,
} from "@phosphor-icons/react";
import { logoutAction } from "../../../setup/store/user";
import classes from "../News.module.scss";
import Input from "../../../components/UI/Input/Input";
import { useEffect, useMemo } from "react";
import React from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";

const Navbar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const user = useSelector((state: any) => state.user);

  const menus = useMemo(
    () => [
      {
        title: "Início",
        path: "/noticias",
      },
      {
        title: "Aplicações",
        path: user.id_user ? "/start" : "/login",
      },
      {
        title: "Ementa",
        path: "/noticias/ementa",
      },
      {
        title: "Protocolos",
        path: "/noticias/protocolos",
      },
      {
        title: "Lista telefónica",
        path: "/noticias/lista-telefonica",
      },
      {
        title: "twogo",
        path: "/noticias/twogo",
      },
    ],
    [user]
  );

  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    if (search.get("news") && search.get("news")!.length) {
      if (location !== "/noticias")
        navigate({ pathname: "/noticias", search: search.toString() });
    }
  }, [search.get("news")]);

  return (
    <div
      className="col-12 mb-10"
      style={{ position: "sticky", top: "0px", zIndex: 5 }}
    >
      <div className="d-flex align-items-start justify-content-between p-4 bg-white">
        <div className="d-flex align-items-center">
          <Dropdown className={classes.hamburguer} autoClose>
            <Dropdown.Toggle as={CustomToggle}>
              <List size={18} weight="bold" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-3">
              {menus.map((menu: any, index: number) => {
                return (
                  <Dropdown.Item
                    key={index}
                    className={clsx(
                      "fw-bold px-5 py-3 rounded mb-2",
                      location === menu.path && "bg-primary text-white"
                    )}
                    onClick={() => {
                      navigate({
                        pathname: menu.path,
                        search: search.toString(),
                      });
                    }}
                  >
                    {menu.title}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <img
            src="/media/logo_rpd/Riopele_Digital_dark.svg"
            width="160px"
            className={`${classes.navLogo} pointer`}
            onClick={() => {
              navigate("/noticias");
            }}
          />
          <div className={classes.menus}>
            {menus.map((menu: any, index: number) => {
              return (
                <Button
                  key={index}
                  title={menu.title}
                  style={`btn ${
                    location === menu.path
                      ? "btn-secondary"
                      : "btn-tertiary text-black"
                  }`}
                  space="mr-2"
                  onClick={() => {
                    navigate({
                      pathname: menu.path,
                      search: search.toString(),
                    });
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Input
            type={"text"}
            placeholder="Pesquisar"
            stateUpdate={(value: any) => {
              setSearch({ news: value });
            }}
            state={search.get("news")}
            size="input-pesquisar-apps"
            icon="MagnifyingGlass"
            clearable
            space=" "
          />
          {!user.id_user ? (
            <Button
              title="Iniciar sessão"
              onClick={() => {
                navigate({
                  pathname: "/login",
                  search: search.toString(),
                });
              }}
              space="ml-5"
            />
          ) : (
            <Dropdown>
              <Dropdown.Toggle className="ml-5 d-flex align-items-center btn-tertiary btn-slim text-black">
                <EmployeeImage
                  id={user.id_colaborador}
                  name={user.primeiro_nome + " " + user.ultimo_nome}
                  size={30}
                  space="mr-3"
                />
                <span className={classes.name}>
                  {user.primeiro_nome + " " + user.ultimo_nome}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="px-5 py-3">
                <Dropdown.Item
                  className="btn btn-tertiary btn-slim text-black fw-bold"
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  <User size={20} className="mr-2" />
                  <span>O meu perfil</span>
                </Dropdown.Item>
                <label className="separator mt-3 mb-3" />
                <Dropdown.Item
                  className="btn btn-tertiary btn-slim text-black fw-bold"
                  onClick={() => {
                    dispatch(logoutAction(user.accessToken));
                  }}
                >
                  <DoorOpen size={20} className="mr-1" />{" "}
                  <span>
                    {intl.formatMessage({ id: "HEADERUSERMENU.LOGOUT" })}
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>

      <label className="separator col-12" />
    </div>
  );
};

const CustomToggle = React.forwardRef<any, any>(
  ({ children, onClick }, ref) => (
    <div ref={ref} onClick={onClick} className={clsx("btn btn-tertiary")}>
      {children}
    </div>
  )
);

export default Navbar;
