import axios from "../axios.config";

export function getContacts(
  search?: string,
  offset?: any,
  order?: any,
  active?: any,
  departamento?: any
) {
  return axios.get(`/telephone`, {
    params: {
      search,
      offset: offset?.toString(),
      order: order?.toString(),
      active,
      departamento,
    },
  });
}

export function getListaFavoritos(accessToken: string) {
  return axios.get(`/telephone/favoritos`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateFavorites(accessToken: string, id: any) {
  return axios.patch(
    `/telephone/favoritos`,
    { telephone: id },
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getTelephoneLocations(accessToken: string) {
  return axios.get(`/telephone/locations`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getTelephoneDepartments() {
  return axios.get(`/telephone/departments`);
}

export function createContact(accessToken: string, body: any) {
  return axios.post(`/telephone`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getContact(accessToken: string, id: any) {
  return axios.get(`/telephone/${id}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateContact(accessToken: string, id: any, body: any) {
  return axios.put(`/telephone/${id}`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateTelephoneDepartment(
  accessToken: string,
  id: any,
  body: any
) {
  return axios.put(`/telephone/department/${id}`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateTelephoneLocation(
  accessToken: string,
  id: any,
  body: any
) {
  return axios.put(`/telephone/location/${id}`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function createTelephoneLocation(accessToken: string, body: any) {
  return axios.post(`/telephone/locations`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function createTelephoneDepartment(accessToken: string, body: any) {
  return axios.post(`/telephone/departments`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function deleteTelephoneDepartment(accessToken: string, id: any) {
  return axios.delete(`/telephone/department/${id}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function deleteTelephoneLocation(accessToken: string, id: any) {
  return axios.delete(`/telephone/location/${id}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}
