import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { logout } from "../../axios/requests/auth";

const userSlice = createSlice({
  name: "user",
  initialState: {
    accessToken: null,
    id_user: null,
    id_colaborador: null,
    id_setor: null,
    num_colaborador: null,
    centro_trabalho: null,
    nome_user: "",
    primeiro_nome: "",
    ultimo_nome: "",
    email: "",
    tipo: "",
    idioma: null,
    local: null,
    ge_id: null,
    gv_id: null,
    agente_id: null,
    chave_setor: null,
    id_fornecedor: null,
    id_responsavel_setor: null,
  },
  reducers: {
    updateToken(state: any, action: PayloadAction<any>) {
      state.accessToken = action.payload.accessToken;
    },

    login(state: any, action: PayloadAction<any>) {
      state.accessToken = action.payload.accessToken;
    },

    logout(state: any) {
      Object.keys(state).forEach((key) => {
        if (key !== "local") state[key] = null;
      });
    },

    saveUser(state: any, action: PayloadAction<any>) {
      state.id_user = action.payload.id;
      state.nome_user = action.payload.nome_user;
      state.primeiro_nome = action.payload.primeiro_nome;
      state.ultimo_nome = action.payload.ultimo_nome;
      state.email = action.payload.email;
      state.id_colaborador = action.payload.id_colaborador;
      state.num_colaborador = action.payload.num_colaborador;
      state.id_setor = action.payload.id_setor;
      state.tipo = action.payload.tipo;
      state.centro_trabalho = action.payload.centro_trabalho;
      state.idioma = action.payload.idioma?.trim();
      state.vista_pdc = action.payload.vista_pdc;
      state.agente_id = action.payload.agente_id;
      state.ge_id = action.payload.ge_id;
      state.gv_id = action.payload.gv_id;
      state.chave_setor = action.payload.chave_setor;
      state.id_fornecedor = action.payload.id_fornecedor;
      state.id_responsavel_setor = action.payload.id_responsavel_setor;
    },

    updateProfile(state: any, action: PayloadAction<any>) {
      state.primeiro_nome = action.payload.primeiro_nome;
      state.ultimo_nome = action.payload.ultimo_nome;
    },

    updateLocal(state: any, action: PayloadAction<any>) {
      state.local = action.payload.local;
    },

    updatePdc(state: any, action: PayloadAction<any>) {
      state.vista_pdc = action.payload.vista_pdc;
    },
  },
});

export const logoutAction = (token: any) => {
  return async (dispatch: any) => {
    logout(token).then(() => {
      localStorage.removeItem("token");
      dispatch(userActions.logout());
    });
  };
};

export const userActions = userSlice.actions;

export default userSlice;
