import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Outlet, useNavigate } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barThickness: 1,
  shadowBlur: 5,
});

const NewsLayout = () => {
  return (
    <div className="col-12 d-flex flex-column align-items-center">
      <Navbar />
      <div
        className="col-12 col-xl-10 col-xxl-9 px-5 mb-15"
        style={{ minHeight: "calc(100vh - 70px)", maxWidth: "1500px" }}
      >
        <Suspense fallback={<TopBarProgress />}>
          <Outlet />
        </Suspense>
      </div>
      <Footer />
    </div>
  );
};

export default NewsLayout;
