import axios from "../axios.config";

export function updateUser(accessToken: string, body: any, id: number) {
  return axios.put(`/users/${id}`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateProfile(accessToken: string, body: any) {
  return axios.put(`/users/profile`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateLanguage(access_token: string, lang: string) {
  return axios.patch(
    "/users/language",
    {
      idioma: lang,
    },
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
}

export function patchPdcView(access_token: string, view: 1 | 2) {
  return axios.patch(
    "/users/pdc",
    {
      view,
    },
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
}
