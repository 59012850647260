import React from "react";
import clsx from "clsx";
import { useLocation } from "react-router";
import { GetIcon } from "../../../../../setup/utilities/icons";
import { AsideMenuItem } from "./AsideMenuItem";
import { checkIsActive } from "../../../helpers";
import { useLang } from "../../../i18n/Metronici18n";
import { getMenuName } from "../../../../../setup/utilities/utilities";

type Props = {
  menu: {
    pai_id: number;
    icon: string;
    rota: string;
    designacao: string;
    designacao_en?: string;
    children: any;
  };
};

const AsideMenuItemWithSub: React.FC<Props> = ({ menu }) => {
  const lang = useLang();
  const { pathname } = useLocation();

  const isActive = checkIsActive(pathname, menu);

  const JSXIcon = GetIcon(menu.icon);

  return (
    <div
      className={clsx("menu-item", "menu-accordion", { "here show": isActive })}
      data-kt-menu-trigger="click"
    >
      <span className="menu-link">
        {JSXIcon && <JSXIcon weight="bold" size={20} />}
        <span className="menu-title">{getMenuName(menu, lang)}</span>
        <span className="menu-arrow"></span>
      </span>
      <div
        className={clsx("menu-sub menu-sub-accordion", {
          "menu-active-bg": isActive,
        })}
      >
        {menu.children.map((children: any, index: number) => {
          if (children.children) {
            return <AsideMenuItemWithSub menu={children} key={index} />;
          }
          return <AsideMenuItem menu={children} key={index} />;
        })}
      </div>
    </div>
  );
};

export { AsideMenuItemWithSub };
