import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive } from "../../../helpers";
import { GetIcon } from "../../../../../setup/utilities/icons";
import { useSelector } from "react-redux";
import { useLang } from "../../../i18n/Metronici18n";
import { getMenuName } from "../../../../../setup/utilities/utilities";

type Props = {
  menu: {
    pai_id: number | null;
    icon: string;
    rota: string;
    designacao: string;
    designacao_en?: string;
    redirect?: string;
    pbi?: string;
  };
};

const AsideMenuItem: React.FC<Props> = ({ menu }) => {
  const lang = useLang();

  const user = useSelector((state: any) => state.user);

  const { pathname } = useLocation();

  const isActive = checkIsActive(pathname, menu);

  const JSXIcon = GetIcon(menu.icon);

  function getLink() {
    if (
      menu.redirect &&
      menu.redirect.startsWith(process.env.REACT_APP_OLD_SITE!)
    ) {
      return menu.redirect! + `?token=${user.accessToken}`;
    } else {
      return menu.redirect!;
    }
  }

  return (
    <div className={clsx("menu-item")}>
      {menu.redirect ? (
        <div
          className={clsx("menu-link without-sub")}
          onClick={() => {
            window.open(getLink(), "_blank");
          }}
        >
          {JSXIcon && <JSXIcon weight="bold" size={20} />}
          <span className="menu-title">{getMenuName(menu, lang)}</span>
        </div>
      ) : (
        <Link
          className={clsx("menu-link without-sub", { active: isActive })}
          to={menu.rota || "/"}
        >
          {JSXIcon && <JSXIcon weight="bold" size={20} />}
          <span className="menu-title">{getMenuName(menu, lang)}</span>
        </Link>
      )}
    </div>
  );
};

export { AsideMenuItem };
