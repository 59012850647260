import { Outlet } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import { Suspense } from "react";
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barThickness: 1,
  shadowBlur: 5,
});

const AutoAvaliacaoLayout: React.FC = () => {
  return (
    <>
      <Navbar />
      <Suspense fallback={<TopBarProgress />}>
        <Outlet />
      </Suspense>
    </>
  );
};

export default AutoAvaliacaoLayout;
