import { SpinnerGap } from "@phosphor-icons/react";
import { toAbsoluteUrl } from "../../components/_metronic/helpers";
import classes from "./Loading.module.css";

type Props = {
  show_image?: boolean;
};

const Loading: React.FC<Props> = ({ show_image }) => {
  return (
    <div className={classes.mainDiv}>
      {show_image && (
        <img
          src={toAbsoluteUrl("/media/logo_rpd/Riopele_Digital.svg")}
          alt="logo"
          width="260px"
          className="mb-15"
        />
      )}
      <SpinnerGap className={classes.icon} size={30} weight="bold" />
    </div>
  );
};
export default Loading;
