import clsx from "clsx";

type Props = {
  id: any;
  state: any;
  stateUpdate: any;
  value: any;
  uncheckValue?: any;
  param?: any;
  title?: any;
  size?: any;
  space?: any;
  disabled?: any;
  border?: boolean;
  padding?: string;
  moddifiers?: string;
};

const Checkbox: React.FC<Props> = ({
  id,
  state,
  stateUpdate,
  value,
  param,
  title,
  uncheckValue,
  size,
  space,
  disabled,
  border,
  padding,
  moddifiers,
}) => {
  return (
    <div
      className={clsx(size && size, space && space, !space && border && "mb-2")}
    >
      <label
        className={clsx(
          title && "form-check-title",
          !border && "no-border",
          padding && padding,
          "form-check-input",
          "pointer"
        )}
        htmlFor={id}
      >
        <input
          id={id}
          className={clsx("pointer")}
          type="checkbox"
          onChange={(e) => {
            if (param) {
              if (e.target.checked) {
                stateUpdate(value, param);
              } else if (uncheckValue != null) {
                stateUpdate(uncheckValue, param);
              }
            } else {
              if (e.target.checked) {
                stateUpdate(value);
              } else if (uncheckValue != null) {
                stateUpdate(uncheckValue);
              }
            }
          }}
          checked={state == value}
          disabled={disabled}
        />
        {title ? <span className={clsx(moddifiers)}>{title}</span> : null}
      </label>
    </div>
  );
};

export default Checkbox;
