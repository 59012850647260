import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import * as menusCRUD from "../../axios/requests/menus";

const menusSlice = createSlice({
  name: "menus",
  initialState: {
    menus: null,
    search: "",
  },
  reducers: {
    saveMenus(state: any, action: PayloadAction<any>) {
      state.menus = action.payload.menus;
    },
    updateSearch(state: any, action: PayloadAction<any>) {
      state.search = action.payload.search;
    },
  },
});

export const getMenus = (token: string, id_user: number) => {
  return async (dispatch: any) => {
    menusCRUD.getUserMenus(token, id_user).then((res: any) => {
      dispatch(
        menusActions.saveMenus({
          menus: res.data,
        })
      );
    });
  };
};

export const menusActions = menusSlice.actions;

export default menusSlice;
