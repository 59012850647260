import { AsideMenuItem } from "./AsideMenuItem";
import { useDispatch, useSelector } from "react-redux";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { menusActions } from "../../../../../setup/store/menus";
import { useIntl } from "react-intl";
import { useLang } from "../../../i18n/Metronici18n";

export function AsideMenuMain() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const lang = useLang();

  const userMenus = useSelector((state: any) => state.menus.menus);
  const search = useSelector((state: any) => state.menus.search);
  const location = useLocation().pathname;

  useEffect(() => {
    if (search) {
      dispatch(menusActions.updateSearch({ search: "" }));
    }
  }, [location]);

  function getName(menu: any) {
    if (lang === "en" && menu.designacao_en) {
      return menu.designacao_en;
    }

    return menu.designacao;
  }

  const menuTree = useMemo(() => {
    let allMenus = JSON.parse(
      JSON.stringify(userMenus.filter((menu: any) => menu.mostrar_menu === "S"))
    );

    if (search && search.length) {
      allMenus = allMenus.map((menu: any) => {
        const parent = menu.pai_id
          ? userMenus.find((obj: any) => obj.id === menu.pai_id)
          : null;

        return {
          ...menu,
          designacao: `${parent ? getName(parent) + " - " : ""}${getName(
            menu
          )}`,
        };
      });
      allMenus = allMenus.filter(
        (obj: any) => !allMenus.some((obj2: any) => obj2.pai_id === obj.id)
      );
      return allMenus.filter((menu: any) =>
        menu.designacao.toLowerCase().includes(search.toLowerCase())
      );
    } else {
      const menus = [allMenus.filter((obj: any) => !obj.pai_id)];

      for (let i = 0; i < menus.length; i++) {
        const children = allMenus.filter((menu: any) =>
          menus[i].some((menu2: any) => menu2.id === menu.pai_id)
        );
        if (children.length) {
          menus[i + 1] = children;
        }
      }

      for (let i = menus.length - 1; i !== 0; i--) {
        menus[i - 1].forEach((menu: any) => {
          const children = menus[i].filter(
            (menu2: any) => menu2.pai_id === menu.id
          );
          if (children.length) {
            menu.children = children;
          }
        });
      }

      return menus[0];
    }
  }, [userMenus, search]);

  return (
    <>
      {userMenus.some((menu: any) => menu.favorito === "S") ? (
        <div className="mb-5">
          <label className="aside-label">
            {intl.formatMessage({
              id: "ASIDEMENU.FAVORITES",
            })}
          </label>
          {[...userMenus]
            .filter((obj: any) => obj.favorito === "S")
            .map((menu: any) => {
              return <AsideMenuItem menu={menu} key={menu.id} />;
            })}
        </div>
      ) : null}
      <div>
        <label className="aside-label">
          {intl.formatMessage({
            id: "ASIDEMENU.ALLMENUS",
          })}
        </label>
        {!search.length ? (
          <AsideMenuItem
            menu={{
              pai_id: null,
              icon: "House",
              rota: "/start",
              designacao: "Início",
              designacao_en: "Start",
            }}
          />
        ) : null}
        {menuTree.map((menu: any, index: number) => {
          if (menu.children) {
            return <AsideMenuItemWithSub menu={menu} key={index} />;
          }
          return <AsideMenuItem menu={menu} key={index} />;
        })}
        {search.length && !menuTree.length ? <p>Sem resultados</p> : null}
      </div>
    </>
  );
}
