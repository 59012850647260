import { FC, useEffect } from "react";
import { useLang } from "./Metronici18n";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/pt";

import ptMessages from "./messages/pt.json";
import enMessages from "./messages/en.json";
import moment from "moment";

const allMessages = {
  pt: ptMessages,
  en: enMessages,
};

const I18nProvider: FC = ({ children }) => {
  const locale = useLang();
  const messages = allMessages[locale];
  useEffect(() => {
    moment.locale(locale);
  }, [locale]);
  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };
