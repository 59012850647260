export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, menu: any) {
  const current = getCurrentUrl(pathname);

  if (menu.children) {
    let menus = [...menu.children];
    for (let i = 0; i < menus.length; i++) {
      if (menus[i] && menus[i].children) {
        menus = [...menus, ...menus[i].children];
      }
    }
    if (
      menus.some(
        (menu: any) => menu.rota !== "/" && current.startsWith(menu.rota)
      )
    ) {
      return true;
    }
  } else if (menu.rota !== "/" && current.startsWith(menu.rota)) {
    return true;
  }

  return false;
}
