import { FC } from "react";
import Button from "../../../../UI/Button/Button";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Header: FC = () => {
  const user = useSelector((state: any) => state.user);

  return (
    <div className="header-menu align-items-center">
      {user.local ? (
        <Link to="/noticias">
          <Button
            title="Notícias"
            icon="Newspaper"
            style="btn btn-tertiary text-black"
          />
        </Link>
      ) : null}
    </div>
  );
};

export { Header };
