import { useSelector } from "react-redux";
import clsx from "clsx";
import {
  getFirstLastName,
  getInitials,
} from "../../../setup/utilities/utilities";
import Loading from "../../../pages/Loading/Loading";
import { useQuery } from "@tanstack/react-query";
import axios from "../../../axios/axios.config";

type Props = {
  id?: number;
  name?: string;
  onClick?: any;
  size?: number;
  space?: string;
  showEmployeeNumber?: boolean;
  showEmployeeName?: boolean;
  accessToken?: any;
};

const EmployeeImage: React.FC<Props> = ({
  id,
  name,
  size,
  onClick,
  space,
  showEmployeeNumber,
  showEmployeeName,
  accessToken,
}) => {
  const user = useSelector((state: any) => state.user);

  async function getEmployeeCard(signal?: any) {
    if (id) {
      let response: any = await axios.get(
        `/rh_ad/employee/card-info?id=${id}`,
        {
          signal,
          headers: {
            authorization: `Bearer ${user.accessToken || accessToken}`,
          },
        }
      );
      response = response.data;

      if (response) {
        response.nome = getFirstLastName(response.nome);

        const annex = response.anexos[0];
        if (annex) {
          const image = await fetch(
            `${process.env.REACT_APP_FTP_EMPLOYEES}${id}/${annex.id}.${annex.extensao}`
          );

          const blob = await image.blob();
          if (blob.type.startsWith("image")) {
            response.image = URL.createObjectURL(blob);
          }
        }
        return response;
      }
    }

    return { nome: name };
  }

  const { data, isLoading }: any = useQuery({
    queryKey: ["employeeImage", id || name],
    queryFn: ({ signal }) => getEmployeeCard(signal),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return (
    <div
      onClick={() => {
        if (onClick) onClick();
      }}
      className={clsx(space && space, "d-flex flex-column align-items-center")}
      style={{
        minWidth: size ? `${size}px` : "50px",
        minHeight: size ? `${size}px` : "50px",
      }}
    >
      {!isLoading ? (
        <>
          {data?.image ? (
            <img
              style={{
                width: size ? `${size}px` : "50px",
                height: size ? `${size}px` : "50px",
                objectFit: "cover",
                filter: `drop-shadow(0px 0px 6px ${data?.cor_setor}80)`,
                borderRadius: "10px",
              }}
              src={data?.image}
            />
          ) : (
            <div
              style={{
                width: size ? `${size}px` : "50px",
                height: size ? `${size}px` : "50px",
                backgroundColor: data?.cor_setor
                  ? data?.cor_setor
                  : "var(--primary)",
                filter: `drop-shadow(0px 0px 6px ${data?.cor_setor}80)`,
                borderRadius: "10px",
                fontSize: "1.25rem",
              }}
              className="symbol-initials"
            >
              {getInitials(data?.nome)}
            </div>
          )}
          {showEmployeeNumber ? (
            <p className="mt-2 mb-0 fs-5">{data?.num_colaborador}</p>
          ) : null}
          {showEmployeeName ? (
            <p className="fs-5 mt-3 mb-0 fw-bold text-primary">{data?.nome}</p>
          ) : null}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default EmployeeImage;
