import axios from "../axios.config";

export function getAllMenus(accessToken: string, eliminado?: "S" | "N") {
  return axios.get(`/menus${eliminado ? `?eliminado=${eliminado}` : ""}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getUserMenus(accessToken: string, id: number, mobile?: any) {
  return axios.get(`/menus/user/${id}${mobile ? `?mobile=${mobile}` : ""}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateUserMenus(
  accessToken: string,
  body: any,
  id_user: number
) {
  return axios.put(`/menus/user/${id_user}`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function createMenu(menu: any, accessToken: string) {
  return axios.post(`/menus`, menu, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateMenu(menu: any, id: number, accessToken: string) {
  return axios.put(`/menus/${id}`, menu, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateMenusOrder(
  menus: any,
  userId: number,
  accessToken: string
) {
  return axios.put(
    `/menus/${userId}/order`,
    { menus },
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function updateFavorites(accessToken: string, body: any) {
  return axios.put(`/menus/favorites`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getUsersByMenu(accessToken: string, id: number) {
  return axios.get(`/menus/sort/user/${id}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function updateMenuUsers(accessToken: string, id: number, body: any) {
  return axios.put(`/menus/sort/user/${id}`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getUsersByMenuKey(accessToken: string, key: string) {
  return axios.get(`/menus/users/key/${key}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}
