import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getContacts,
  getListaFavoritos,
  updateFavorites,
} from "../../../../../../axios/requests/contacts";
import { useIntl } from "react-intl";
import { Heart, X } from "@phosphor-icons/react";
import SmallLoading from "../../../../../../pages/Loading/SmallLoading";
import EmployeeImage from "../../../../../UI/EmployeeImage/EmployeeImage";

import clsx from "clsx";
import "./Contacts.css";
import LabelInput from "../../../../../UI/Input/Input";
import { getInitials } from "../../../../../../setup/utilities/utilities";

const Contacts = ({ isOpen, setIsOpen }: any) => {
  const intl = useIntl();

  const token = useSelector((state: any) => state.user.accessToken);

  const [contacts, setContacts] = useState<any>();
  const [favorites, setFavorites] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState("");

  const [selected, setSelected] = useState<any>(null);

  useEffect(() => {
    let timeout: any;
    if (input.length > 2) {
      timeout = setTimeout(() => {
        setLoading(true);
        getContacts(input)
          .then((res) => {
            setContacts(res.data.contacts);
          })
          .catch(() => {
            setContacts([]);
          });
      }, 300);
    } else {
      setContacts([]);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [input]);

  useEffect(() => {
    if (contacts) {
      setLoading(false);
    }
  }, [contacts]);

  useEffect(() => {
    getListaFavoritos(token).then((res) => {
      setFavorites(res.data);
    });
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.getElementById("contactsInput")!.focus();
    }
  }, [isOpen]);

  const update = (contact: any) => {
    if (selected && contact.id == selected.id) {
      setSelected(null);
    }

    updateFavorites(token, contact.id).then(() => {
      if (favorites.findIndex((obj: any) => obj.id == contact.id) >= 0) {
        setFavorites(favorites.filter((obj: any) => obj.id != contact.id));
      } else {
        setFavorites([...favorites, contact]);
      }
    });
  };

  function isFavorite(contact: any) {
    return favorites.findIndex((obj: any) => obj.id == contact.id) >= 0;
  }

  return (
    <div
      id="kt_engage_demos"
      data-kt-drawer="true"
      data-kt-drawer-name="explore"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'100%',md:'400px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_show_contacts_toggle"
      data-kt-drawer-close="#kt_show_contacts_close"
      className="pl-10 pr-5 page-pdc-lista-telefonica"
      style={{ overflowX: "hidden" }}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          background: "white",
        }}
        className="pt-5"
      >
        <div className="d-flex justify-content-between">
          <p
            style={{
              fontWeight: "600",
              fontSize: "20px",
              color: "#1B2A42",
              lineHeight: "25px",
              letterSpacing: "-0.2px",
            }}
          >
            {intl.formatMessage({ id: "CONTACTS.TITLE" })}
          </p>
          <div id="kt_engage_demos_close">
            <X
              size={25}
              id="kt_show_contacts_close"
              onClick={() => {
                setIsOpen(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        {favorites.length ? (
          <div>
            <p className="subtitulo mb-5">
              {intl.formatMessage({ id: "ASIDEMENU.FAVORITES" })}
            </p>
            <div className="d-flex flex-wrap mb-10">
              {favorites.map((contact: any) => {
                return (
                  <div
                    key={contact.id}
                    className="d-flex justify-content-center col-3"
                  >
                    <div
                      className={clsx(
                        "contacts-card",
                        selected?.id === contact.id && "active"
                      )}
                      onClick={() => {
                        setSelected(contact);
                      }}
                    >
                      <EmployeeImage
                        size={55}
                        id={contact.id_colaborador}
                        name={contact.nome}
                        space="mb-1"
                      />
                      <span>{getInitials(contact.nome)}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}

        {selected ? (
          <Contact obj={selected} updateFavorite={update} favorite={true} />
        ) : null}

        <p className="subtitulo mb-5 mt-7">
          {intl.formatMessage({ id: "CONTACTS.ALLCONTACTS" })}
        </p>

        <LabelInput
          id="contactsInput"
          type={"text"}
          state={input}
          stateUpdate={setInput}
          placeholder={intl.formatMessage({ id: "CONTACTS.INPUT" })}
          clearable
          size="col-12"
          space={"mb-5"}
        />
      </div>

      {!loading ? (
        <div className="d-flex flex-column justify-content-center pr-2">
          {contacts.length == 0 && input != "" && !loading && (
            <label className="text-center">
              <p> {intl.formatMessage({ id: "CONTACTS.NODATA" })}</p>
            </label>
          )}
          {contacts.length
            ? contacts.map((contact: any) => {
                return (
                  <Contact
                    key={contact.id}
                    favorite={isFavorite(contact)}
                    obj={contact}
                    updateFavorite={update}
                  />
                );
              })
            : null}
        </div>
      ) : (
        <div className="text-align-center">
          <SmallLoading />
        </div>
      )}
      <div className="masked-overflow">&nbsp;</div>
    </div>
  );
};

export default Contacts;

type Props = {
  obj: any;
  updateFavorite?: any;
  favorite: any;
};

const Contact: React.FC<Props> = ({ obj, updateFavorite, favorite }) => {
  const [nameLength, setNameLength] = useState(18);

  return (
    <div key={obj.id_colaborador}>
      <div className="d-flex justify-content-between align-items-center col-12 mb-2">
        <div className="d-flex">
          <EmployeeImage
            size={40}
            {...(obj.id_colaborador
              ? { id: obj.id_colaborador }
              : { name: obj.nome })}
            name={obj.nome}
            space="mr-3"
          />
          <div>
            <p
              className="nome mb-0 mt-1"
              onMouseOver={() => {
                setNameLength(0);
              }}
              onMouseOut={() => {
                setNameLength(18);
              }}
            >
              {nameLength > 0 && obj.nome.length > nameLength
                ? obj.nome.substring(0, nameLength) + "..."
                : obj.nome}
            </p>

            <p className="depart m-0">{obj.departamento}</p>
          </div>
        </div>
        <div className="d-flex align-items-center">
          {obj.ext ? (
            <span className="fs-1 fw-bolder mr-2">{obj.ext}</span>
          ) : null}
          <Heart
            className="pointer"
            size={20}
            color="#F7686A"
            weight={favorite ? "fill" : "regular"}
            onClick={() => updateFavorite(obj)}
          />
        </div>
      </div>
      <div>
        {obj.telemovel || obj.ddi || obj.abreviado ? (
          <table className="contacts-table">
            <tbody>
              {obj.telemovel && (
                <tr>
                  <td className="">TLM:</td>
                  <td className="fw-bold">{obj.telemovel}</td>
                </tr>
              )}
              {obj.ddi && (
                <tr>
                  <td>DDI:</td>
                  <td className="fw-bold">{obj.ddi}</td>
                </tr>
              )}
              {obj.abreviado && (
                <tr>
                  <td>ABR:</td>
                  <td className="fw-bold">{obj.abreviado}</td>
                </tr>
              )}
            </tbody>
          </table>
        ) : null}
      </div>
      <label className="separator my-5" />
    </div>
  );
};
