import axios from "../axios.config";

export function signin(username: string, password: string, remember: boolean) {
  return axios.post(`/auth/signin${remember ? "?remember=S" : ""}`, {
    nome_user: username,
    pwd_user: password,
  });
}

export function singup(body: any) {
  return axios.post(`/auth/signup`, body);
}

export function logout(accessToken: string) {
  return axios.delete("/auth/logout", {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function verifyToken(accessToken: string) {
  return axios.get("/auth/verifyToken", {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function recoverPassword(email: string) {
  return axios.post("/users/recover/token", { email });
}

export function updatePassword(token: string, email: string, password: string) {
  return axios.post(`/users/recover`, {
    pwd_user: password,
    token,
    email,
  });
}

export function getNotifications(access_token: string, parameter: any) {
  return axios.get(`/utilities/parameters/${parameter}`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
}

export function unblockUser(access_token: string, body: any) {
  return axios.post(`/auth/unblock`, body, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
}
