import clsx from "clsx";
import { useLayout } from "../../core";
import { Header } from "./Header";
import { Topbar } from "./Topbar";
import { toAbsoluteUrl } from "../../../helpers";
import { useNavigate } from "react-router-dom";
import { List } from "@phosphor-icons/react";

export function HeaderWrapper({ setIsOpen }: any) {
  const navigate = useNavigate();
  const { config, classes, attributes } = useLayout();
  const { header } = config;

  return (
    <div
      id="kt_header"
      className={clsx("header", classes.header.join(" "))}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(" "),
          "d-flex align-items-center justify-content-between"
        )}
      >
        <div
          id="kt_aside_mobile_toggle"
          className="d-flex align-items-center pointer"
        >
          <List size={25} className="mr-3" weight="bold" />
          <img
            src={toAbsoluteUrl("/media/logo_rpd/Riopele_Digital_dark.svg")}
            className="h-25px"
            onClick={() => {
              navigate("/start");
            }}
          />
        </div>

        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          {header.left === "menu" && (
            <div className="d-flex align-items-stretch" id="kt_header_nav">
              <Header />
            </div>
          )}

          <div className="d-flex align-items-stretch flex-shrink-0">
            <Topbar setIsOpen={setIsOpen} />
          </div>
        </div>
      </div>
    </div>
  );
}
