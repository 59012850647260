/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { Bell, BellRinging } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getNotifications,
  updateNotifications,
} from "../../../../../../axios/requests/users";
import "./Notifications.css";
import { useIntl } from "react-intl";

const Notifications = () => {
  const user = useSelector((state: any) => state.user);
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState<any>([]);

  const intl = useIntl();

  const getUserNotifications = () => {
    getNotifications(user.accessToken).then((res) => {
      setNotifications(res.data);
    });
  };

  useEffect(() => {
    getUserNotifications();
  }, []);

  function updateSeen() {
    if (notifications.some((obj: any) => obj.lida == "N"))
      updateNotifications(user.accessToken, {
        notifications: notifications.map((obj: any) => obj.id),
      });
  }

  return (
    <div
      data-kt-menu-trigger="click"
      data-kt-menu-attach="parent"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="bottom"
      onClick={() => {
        updateSeen();
      }}
      className="btn btn-slim mr-2"
    >
      {notifications.some((obj: any) => obj.lida == "N") ? (
        <>
          <BellRinging weight="bold" size={22} />
          <span className="dot"></span>
        </>
      ) : (
        <Bell weight="bold" size={22} />
      )}
      <div
        className="menu menu-sub menu-sub-dropdown menu-column w-350px px-7 py-4"
        data-kt-menu="true"
      >
        <p
          className="mb-8"
          style={{
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#1b2a42",
            textAlign: "start",
          }}
        >
          {intl.formatMessage({
            id: "TOPBAR.NOTIFICATIONS",
          })}
        </p>
        <div className="scroll-y mh-300px page-notifications">
          {notifications.map((item: any, index: number) => (
            <div
              onClick={() => {
                navigate("/rpeople/evaluations");
              }}
              className="mb-5 d-flex"
              key={index}
            >
              {item.lida == "N" ? (
                <span className="dot-list mr-3 ml-1 my-1"></span>
              ) : null}
              <div className="d-flex flex-column gap-1 text-align-left">
                <p className="title mb-0">{item.mensagem}</p>
                <p className="subtitle mb-0">{item.nome}</p>
                <p className="date mb-0">{moment(item.data).format("LL")}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
