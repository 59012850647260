import clsx from "clsx";
import classes from "./LoadingButton.module.css";
import { GetIcon } from "../../../setup/utilities/icons";
import { CircleNotch } from "@phosphor-icons/react";

type Props = {
  loading: boolean;
  title?: string;
  submit?: boolean;
  style?: string;
  onClick?: any;
  icon?: string;
  iconPos?: string;
  disabled?: boolean;
  space?: any;
  form?: any;
};

const LoadingButton: React.FC<Props> = ({
  loading,
  title,
  submit,
  style,
  icon,
  iconPos,
  onClick,
  disabled,
  space,
  form,
}) => {
  const JSXIcon = icon ? GetIcon(icon) || null : null;
  return (
    <button
      className={clsx(
        style ? style : "btn btn-primary",
        classes.button,
        icon && "d-flex align-items-center",
        space && space
      )}
      type={submit ? "submit" : "button"}
      disabled={loading || disabled}
      onClick={onClick ? onClick : () => {}}
      form={form}
    >
      {iconPos === "right" ? title : null}
      {loading ? (
        <CircleNotch
          className={clsx(
            classes.icon,
            title && `${iconPos === "right" ? "ml-2" : "mr-2"}`
          )}
          size={20}
        />
      ) : (
        <>
          {JSXIcon ? (
            <JSXIcon
              size={20}
              weight="bold"
              className={clsx(
                title && `${iconPos === "right" ? "ml-2" : "mr-2"}`
              )}
            />
          ) : null}
        </>
      )}
      {iconPos !== "right" ? title : null}
    </button>
  );
};

export default LoadingButton;
