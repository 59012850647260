import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  TwitterLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";
import classes from "../News.module.scss";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <span>© 2023 Riopele Group</span>
      <div>
        <a href="https://www.facebook.com/RIOPELE" target="_blank">
          <FacebookLogo size={20} weight="bold" />
        </a>
        <a href="https://www.linkedin.com/company/riopele" target="_blank">
          <LinkedinLogo size={20} weight="bold" />
        </a>
        <a href="https://www.instagram.com/riopele.pt" target="_blank">
          <InstagramLogo size={20} weight="bold" />
        </a>
        <a href="https://twitter.com/riopele" target="_blank">
          <TwitterLogo size={20} weight="bold" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCf2JdtmzlEc2fVVTXdXe2Nw?cbrd=1"
          target="_blank"
        >
          <YoutubeLogo size={20} weight="bold" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
