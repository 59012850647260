import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const selfEvaluationSlice = createSlice({
  name: "self_evaluation",
  initialState: {
    accessToken: null,
    nome: "",
    id_colaborador: null,
  },
  reducers: {
    login(state: any, action: PayloadAction<any>) {
      state.accessToken = action.payload.accessToken
        ? action.payload.accessToken
        : state.accessToken;
      state.nome = action.payload.nome ? action.payload.nome : state.nome;
      state.id_colaborador = action.payload.id_colaborador
        ? action.payload.id_colaborador
        : state.id_colaborador;
    },

    logout(state: any) {
      state.accessToken = null;
      state.nome = null;
      state.id_colaborador = null;
      localStorage.removeItem("self_evaluation_token");
    },
  },
});

export const selfEvaluationActions = selfEvaluationSlice.actions;

export default selfEvaluationSlice;
