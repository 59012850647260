import { configureStore } from "@reduxjs/toolkit";

import userSlice from "./user";
import menusSlice from "./menus";
import selfEvaluationSlice from "./selfevaluation";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    menus: menusSlice.reducer,
    self_evaluation: selfEvaluationSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;

export default store;
