import React, { useRef } from "react";
import clsx from "clsx";
import { AsideMenuMain } from "./AsideMenuMain";
import { useDispatch, useSelector } from "react-redux";
import EmployeeImage from "../../../../UI/EmployeeImage/EmployeeImage";
import { useNavigate } from "react-router-dom";
import Input from "../../../../UI/Input/Input";
import { menusActions } from "../../../../../setup/store/menus";
import { useIntl } from "react-intl";

type Props = {
  asideMenuCSSClasses: string[];
};

const AsideMenu: React.FC<Props> = ({ asideMenuCSSClasses }) => {
  const user = useSelector((state: any) => state.user);
  const search = useSelector((state: any) => state.menus.search);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const scrollRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      id="kt_aside_menu_wrapper"
      ref={scrollRef}
      data-kt-scroll="true"
      data-kt-scroll-activate="{default: false, lg: true}"
      data-kt-scroll-height="auto"
      data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
      data-kt-scroll-wrappers="#kt_aside_menu"
      data-kt-scroll-offset="0"
      className="d-flex flex-column"
    >
      <div className="mb-3">
        <div
          id="kt_aside_user"
          className="aside-user pointer"
          onClick={() => {
            navigate("/profile");
          }}
        >
          <EmployeeImage
            id={user.id_colaborador}
            name={`${user.primeiro_nome} ${user.ultimo_nome}`}
            size={70}
            showEmployeeName
          />
        </div>
        <div id="kt_aside_search" className="aside-search px-1">
          <Input
            type={"text"}
            placeholder={intl.formatMessage({ id: "ASIDEMENU.SEARCH" })}
            stateUpdate={(value: any) => {
              dispatch(menusActions.updateSearch({ search: value }));
            }}
            state={search}
            size="col-12 input-pesquisar-apps"
            icon="MagnifyingGlass"
            clearable
          />
        </div>
      </div>
      <div
        id="#kt_aside_menu"
        data-kt-menu="true"
        className={clsx(
          "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 pr-1",
          asideMenuCSSClasses.join(" ")
        )}
        style={{ overflowY: "scroll" }}
      >
        <AsideMenuMain />
      </div>
    </div>
  );
};

export { AsideMenu };
