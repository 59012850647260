import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { AsideDefault } from "./components/aside/AsideDefault";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { useLocation } from "react-router-dom";
import { MenuComponent } from "../assets/ts/components";
import Contacts from "./components/header/Contacts/Contacts";

const MasterLayout = () => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        {process.env.REACT_APP_DEV === "S" ? (
          <div className="dev_flag">DEV</div>
        ) : null}
        <AsideDefault />
        <div id="kt_wrapper" className="col-12 wrapper">
          <HeaderWrapper setIsOpen={setIsOpen} />
          <Outlet />
        </div>
      </div>
      <Contacts setIsOpen={setIsOpen} isOpen={isOpen} />
    </>
  );
};

export { MasterLayout };
