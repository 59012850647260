import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createFeedbackConfirmation,
  getFeedbackConfirmation,
} from "../../../axios/requests/rpeople";
import { useNavigate, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../../components/_metronic/helpers";
import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import TextArea from "../../../components/UI/TextArea/TextArea";
import Input from "../../../components/UI/Input/Input";
import LoadingButton from "../../../components/UI/LoadingButton/LoadingButton";
import SmallLoading from "../../Loading/SmallLoading";
import { getName } from "../../../setup/utilities/utilities";
import { toast } from "react-toastify";
import { CheckCircle } from "@phosphor-icons/react";

const Feedback: React.FC = () => {
  const user = useSelector((state: any) => state.user.accessToken);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [validating, setValidating] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);

  const navigate = useNavigate();
  const [feedback, setFeedback] = useState<any>({});

  const [check, setCheck] = useState<any>("N");
  const [observations, setObservations] = useState<any>("");
  const [code, setCode] = useState<any>("");
  const [nif, setNif] = useState<number>();

  const hashQuery = useParams().hash;

  function getFeedback(hash: any, letter?: boolean) {
    setIsLoading(true);
    getFeedbackConfirmation(hash, nif ? nif : null)
      .then((res) => {
        setFeedback(res.data);
        if (letter) {
          navigate(`/feedback/${code}`);
          setCode("");
        }
        setIsLoading(false);

        setValidating(false);
      })
      .catch((err) => {
        toast(err.response.data.message, {
          type: "error",
        });
        setCode("");
        setNif(0);
        setIsLoading(false);
        setValidating(false);

        return;
      });
  }

  function createConfirmation() {
    setIsSubmitting(true);
    createFeedbackConfirmation(hashQuery, { observacoes: observations })
      .then((res) => {
        if (res.status === 200) {
          toast("Feedback confirmado com sucesso", {
            type: "success",
          });
          setIsSubmitting(false);
          setConfirmed(true);
        }
      })
      .catch((err) => {
        toast("Não foi possível confirmar o feedback", {
          type: "error",
        });
        setIsSubmitting(false);
      });
  }

  useEffect(() => {
    if (hashQuery) {
      getFeedback(hashQuery);
    }
    setIsLoading(false);
  }, [hashQuery]);

  useEffect(() => {
    if (feedback && feedback.confirmou_feedback == "N" && check == "S") {
      document.getElementById("obs")?.focus();
    }
  }, [feedback.confirmou_feedback && check]);

  return (
    <div
      style={{
        backgroundImage: `url(${toAbsoluteUrl("/media/carousel/1.jpg")})`,
        height: "100vh",
        overflow: "hidden",
        backgroundSize: "cover",
      }}
    >
      <div className="row mt-20 justify-content-center">
        <div
          style={{ background: "rgba(236, 236, 236, 0.8)" }}
          className=" p-5 rounded  col-11 col-sm-9 col-md-6 col-lg-5 col-xl-3 col-xxl-3"
        >
          <div>
            <div className="d-flex justify-content-center">
              <img
                width={"170px"}
                src={toAbsoluteUrl("/media/logo_rpd/rpeopleverde.png")}
              />
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center my-10">
                <SmallLoading />
              </div>
            ) : null}
            {!hashQuery ? (
              <div className="my-5">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setValidating(true);
                    getFeedback(code, true);
                  }}
                >
                  <p className="titulo-apps">
                    Insira o código de acesso que lhe foi enviado
                  </p>
                  <Input
                    type={"text"}
                    state={code}
                    stateUpdate={setCode}
                    title="Código"
                    placeholder="Código"
                  />
                  <Input
                    type={"number"}
                    state={nif}
                    stateUpdate={setNif}
                    title="Nif"
                    placeholder="Nif"
                  />
                  <div className="d-flex justify-content-center">
                    <LoadingButton
                      loading={validating}
                      title="Validar"
                      space={"mt-3"}
                      submit
                    />
                  </div>
                </form>
              </div>
            ) : null}
            {feedback &&
            feedback.confirmou_feedback == "N" &&
            !confirmed &&
            !isLoading ? (
              <>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    createConfirmation();
                  }}
                >
                  <div className="d-flex justify-content-center mt-4 mb-4">
                    <Checkbox
                      id={"check_confirm"}
                      state={check}
                      stateUpdate={setCheck}
                      value={"S"}
                      uncheckValue={"N"}
                      title={`Confirmo que recebi o feedback de ${getName(
                        feedback.nome_avaliador
                      )}`}
                    />
                  </div>
                  {check == "S" ? (
                    <div className="mb-5">
                      <TextArea
                        stateUpdate={setObservations}
                        state={observations}
                        title="Observações"
                        id={"obs"}
                      />
                      <div className="d-flex justify-content-center">
                        <LoadingButton
                          loading={isSubmitting}
                          title="Confirmar"
                          space={"mt-2"}
                          submit
                        />
                      </div>
                    </div>
                  ) : null}
                </form>
              </>
            ) : null}
            {feedback && feedback.confirmou_feedback == "S" && !isLoading ? (
              <p className="titulo-apps text-center my-5">
                Já confirmou a receção de feedback
              </p>
            ) : null}
            {confirmed ? (
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-center mt-4">
                  <CheckCircle
                    size={50}
                    color="var(--primary)"
                    className="justify-self-center"
                  />
                </div>
                <p className="titulo-apps fs-4 text-center my-4">
                  Feedback Confirmado
                </p>
              </div>
            ) : null}

            <div className="pl-5 pr-5 pt-5 d-flex justify-content-center">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logo_rpd/Riopele_Digital_dark.svg")}
                height="25px"
                className="logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
