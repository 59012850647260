import { Check, CircleNotch, Info, Warning } from "@phosphor-icons/react";
import { ToastContainer } from "react-toastify";
import classes from "./Toast.module.css";

const Toast: React.FC = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      pauseOnHover={false}
      closeOnClick={true}
      closeButton={true}
      theme="colored"
      icon={({ theme, type }) => {
        switch (type) {
          case "success":
            return <Check size={20} />;

          case "error":
            return <Warning size={20} />;

          case "warning":
            return <Warning size={20} />;

          case "default":
            return <CircleNotch className={classes.loading} size={20} />;
        }
        return <Warning />;
      }}
    />
  );
};

export default Toast;
