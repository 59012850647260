import { useEffect, useRef } from "react";
import moment from "../moment/moment";
import saveAs from "file-saver";

export function GetValue(value: any, obj1: any, obj2: any) {
  return obj1[value] != null ? obj1[value] : obj2[value];
}

export function GetCustomDate(date1: any, date2: any) {
  if (moment(date1).year() != moment(date2).year()) {
    return moment(date1).format("LL") + " a " + moment(date2).format("LL");
  } else if (
    moment(date1).month() != moment(date2).month() &&
    moment(date1).year() == moment(date2).year()
  ) {
    return moment(date1).format("L") + " a " + moment(date2).format("L");
  } else {
    return moment(date1).format("D") + " a " + moment(date2).format("LL");
  }
}

export function getYears(startYear: number) {
  const years = -moment(startYear, "YYYY").diff(
    moment().format("YYYY"),
    "years"
  );

  let options = [];
  for (let i = 0; i <= years; i++) {
    options.push({
      label: startYear + i,
      value: startYear + i,
    });
  }
  return options;
}

export function getInitials(name: any) {
  try {
    let initials = "";

    if (!name) {
      return initials;
    }

    let names = name.trim().split(" ");

    if (names.length > 1) {
      initials = names[0][0] + names[names.length - 1][0];
    } else {
      initials = names[0][0];
    }

    return initials.toUpperCase();
  } catch {
    return "";
  }
}

export function CompareNumbers(value1: number, value2: number, operator: any) {
  switch (operator) {
    case ">": {
      return +value1 > +value2;
    }
    case ">=": {
      return +value1 >= +value2;
    }
    case "<": {
      return +value1 < +value2;
    }
    case "<=": {
      return +value1 <= +value2;
    }
    case "=": {
      return +value1 == +value2;
    }
  }
}

export function CostumizeHour(value: any) {
  let values = value.split(":");
  values[0] = values[0].replace(/^0+/, "");
  let final = values[0] + "h" + (values[1] == "00" ? "" : values[1]);
  return final;
}

export function useComponentVisible(setIsShown: any, exception?: any) {
  const ref = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (exception && exception != event.target.tagName) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShown(false);
      }
    } else if (!exception) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShown(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref };
}

export function obscureEmail(email: string) {
  const [name, domain] = email.split("@");
  return `${name[0]}${name[1]}${new Array(name.length).join("*")}@${domain}`;
}

export function obscurePhone(phone: string) {
  let toReturn = phone.toString().split("");
  for (let i = 2; i < toReturn.length - 2; i++) {
    toReturn[i] = "*";
  }
  return toReturn.toString().replaceAll(",", "");
}

export function getComparatorName(comparator: any, language?: any) {
  switch (comparator) {
    case ">": {
      switch (language) {
        case "en":
          return "Greater than";
        default:
          return "Maior que";
      }
    }
    case ">=": {
      switch (language) {
        case "en":
          return "Greater than or equal to";
        default:
          return "Maior ou igual a";
      }
    }
    case "<": {
      switch (language) {
        case "en":
          return "Lower than";
        default:
          return "Menor que";
      }
    }
    case ">=": {
      switch (language) {
        case "en":
          return "Lower than or equal to";
        default:
          return "Menor ou igual a";
      }
    }
  }
}

export function flattenArray(array: any, key: any) {
  return array.flatMap((obj: any) =>
    obj[key] !== undefined ? flattenArray(obj[key] || [], key) : obj
  );
}

export function getName(name: string | any) {
  if (name && name.length) {
    const names = name.trim().split(" ");
    return names.reduce(
      (accumulator: any, currentValue: string, currentIndex: number) =>
        accumulator +
        (currentIndex ? " " : "") +
        `${
          currentValue.substring(0, 1).toUpperCase() +
          currentValue.substring(1).toLowerCase()
        }`,
      ""
    );
  }
}

export const getFirstLastName = (name: string) => {
  try {
    const names = name.trim().split(" ");
    if (names.length > 1) {
      return (
        names[0].slice(0, 1).toUpperCase() +
        names[0].slice(1).toLowerCase() +
        " " +
        names[names.length - 1].slice(0, 1).toUpperCase() +
        names[names.length - 1].slice(1).toLowerCase()
      );
    } else {
      return names[0];
    }
  } catch {
    return name;
  }
};

export const getUsername = (name: string) => {
  try {
    const names = name.trim().split(" ");
    if (names.length > 1) {
      return (
        names[0].slice(0, 1).toLowerCase() +
        names[0].slice(1).toLowerCase() +
        "." +
        names[names.length - 1].slice(0, 1).toLowerCase() +
        names[names.length - 1].slice(1).toLowerCase()
      );
    } else {
      return names[0];
    }
  } catch {
    return name;
  }
};

export function getWeekdayKey(weekday: number) {
  switch (weekday) {
    case 0:
      return "domingo";
    case 1:
      return "segunda";
    case 2:
      return "terca";
    case 3:
      return "quarta";
    case 4:
      return "quinta";
    case 5:
      return "sexta";
    case 6:
      return "sabado";
    default:
      return "";
  }
}

export function getMenuName(
  menu: { designacao_en?: string; designacao: string },
  language: any
) {
  if (language === "en" && menu.designacao_en) {
    return menu.designacao_en;
  }

  return menu.designacao;
}

export function diffTime(horaInicio: string, horaFim: string): string {
  if (!horaInicio || !horaFim) {
    return "00:00";
  }
  const [inicioHoras, inicioMinutos] = horaInicio.split(":").map(Number);
  const [fimHoras, fimMinutos] = horaFim.split(":").map(Number);

  const minutosInicio = inicioHoras * 60 + inicioMinutos;
  const minutosFim = fimHoras * 60 + fimMinutos;

  let diffMinutos = minutosFim - minutosInicio;

  if (diffMinutos < 0) {
    diffMinutos += 24 * 60;
  }

  const diffHoras = Math.floor(diffMinutos / 60);
  const minutosRestantes = diffMinutos % 60;

  const horasFormatadas = diffHoras.toString().padStart(2, "0");
  const minutosFormatados = minutosRestantes.toString().padStart(2, "0");

  return `${horasFormatadas}:${minutosFormatados}`;
}

export function getHourFromMinutes(minutes: number, separator?: string) {
  const hours: number = Math.floor(minutes / 60);
  const minutesLeft: number = minutes % 60;

  const result: string = `${hours.toString().padStart(2, "0")}${
    separator ? separator : "h"
  }${minutesLeft.toString().padStart(2, "0")}`;

  return result;
}

export function getMinutesFromHour(hour: number) {
  const [hours, minutes] = String(hour).split(":").map(Number);
  const minutos = hours * 60 + minutes;
  return minutos;
}

export function formatCurrency(value: any, symbol?: boolean) {
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  const formatted = formatter.format(value);

  if (symbol === false) {
    return formatted.substring(0, formatted.length - 2);
  }
  return formatted;
}

export function formatWeight(value: any, unity?: string) {
  const formatter = new Intl.NumberFormat("de-DE");

  return formatter.format(value)?.replaceAll(",", " ") + " " + (unity || "KG");
}

export function htmlToText(html: any) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

export function exportCsv(data: any, name?: string) {
  try {
    data = data
      .map((line: any) => {
        return line
          .map(String)
          .map((value: string) => `"${value}"`)
          .join(";");
      })
      .join("\r\n");

    const blob = new Blob(["\uFEFF" + data], {
      type: "text/csv;charset=utf-8;",
    });

    saveAs(blob, name || `Export.csv`);
  } catch {
    return;
  }
}

export const removePlicas = (str: string) => {
  return str?.replaceAll("&PLICA", "'");
};

export const truncateString = (content: string, characters: number) => {
  return content.length > characters
    ? content.substring(0, characters) + "..."
    : content;
};

// export const hoursToWorkDays = (hours: any) => {
//   const hourPerDay = 8;
//   const days = hours / hourPerDay;
//   return Number.isInteger(days) ? days : parseFloat(days.toFixed(1));
// };

export const hoursToWorkDays = (hours: number) => {
  const horas = Math.floor(hours);
  const minutos = (hours - horas) * 100;

  const horasTotais = horas + minutos / 60;

  const dias = Math.floor(horasTotais / 8);
  const horasRestantes = horasTotais % 8;

  return `${dias}${
    horasRestantes ? ` dias e ${horasRestantes?.toFixed()}h` : ""
  }`;
};

//ABSENTISMO

export const convertToMinutes = (val: number) => {
  const hours = Math.floor(val);
  const minutes = Math.round((val - hours) * 100);
  return hours * 60 + minutes;
};

function convertToHours(minVal: number): string {
  const hours = Math.floor(minVal / 60);
  const minutes = minVal % 60;
  const formatedHours = hours.toString().padStart(2, "0");
  const formatedMinutes = minutes.toString().padStart(2, "0");

  return `${formatedHours == "00" ? "0" : formatedHours}h${
    formatedMinutes == "00" ? "" : formatedMinutes
  }`;
}

export const sumHours = (val1: number, val2: number) => {
  const totalMinutes = convertToMinutes(val1) + convertToMinutes(val2);
  return convertToHours(totalMinutes);
};

export const subHours = (val1: number, val2: number) => {
  const totalMinutes = convertToMinutes(val1) - convertToMinutes(val2);
  return convertToHours(totalMinutes);
};

export const convertHour = (hours: any) => {
  const totalMinutes = convertToMinutes(hours);
  return convertToHours(totalMinutes);
};
