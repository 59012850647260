import { useIntl } from "react-intl";
import classes from "./Loading.module.css";
import { SpinnerGap } from "@phosphor-icons/react";

type Props = {
  onlyIcon?: boolean;
  size?: number;
};

const SmallLoading: React.FC<Props> = ({ onlyIcon, size }) => {
  const intl = useIntl();

  return (
    <div className="d-flex align-items-center">
      <SpinnerGap
        className={classes.icon}
        size={size ? size : 20}
        weight="bold"
      />
      {!onlyIcon ? (
        <span className="fs-6 m-0">
          {intl.formatMessage({ id: "Loading" })}
        </span>
      ) : null}
    </div>
  );
};
export default SmallLoading;
