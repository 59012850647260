import { FC, useEffect, useMemo } from "react";
import { useLang, setLanguage } from "../../../i18n/Metronici18n";
import { Globe } from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { updateLanguage } from "../../../../../axios/requests/user";
import { toast } from "react-toastify";

const languages = [
  {
    lang: "en",
    name: "English",
    flag: "/media/flags/united-kingdom.svg",
  },

  {
    lang: "pt",
    name: "Português",
    flag: "/media/flags/portugal.svg",
  },
];

const Languages: FC = () => {
  const user = useSelector((state: any) => state.user);

  const lang = useLang();

  const currentLanguage = useMemo(
    () => languages.find((x) => x.lang === lang),
    [lang]
  );

  useEffect(() => {
    if (user.idioma && user.idioma !== lang) {
      const exists = languages.find((obj: any) => obj.lang === user.idioma);
      if (exists) {
        setLanguage(user.idioma);
      }
    }
  }, [user.idioma]);

  function updateUserLanguage(lang: string) {
    updateLanguage(user.accessToken, lang)
      .then(() => {
        setLanguage(lang);
      })
      .catch((err) => {
        toast("Não foi possivel atualizar o idioma", {
          type: "error",
        });
      });
  }

  return (
    <div
      className="btn btn-slim mr-2"
      data-kt-menu-trigger="click"
      data-kt-menu-attach="parent"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="bottom"
    >
      <Globe size={20} className="mr-lg-2" weight="bold" />
      <span id="kt-language-title">
        {currentLanguage ? currentLanguage.name : ""}
      </span>
      <div
        data-kt-menu="true"
        className="menu menu-sub menu-sub-dropdown menu-column w-200px"
      >
        {languages.map((language, index) => (
          <div key={"language" + index}>
            <div
              className="d-flex align-items-center text-primary px-3 py-3"
              key={index}
              onClick={() => {
                updateUserLanguage(language.lang);
              }}
            >
              <img className="w-25px mr-3" src={language.flag} />
              {language.name}
            </div>
            {index < languages.length - 1 ? (
              <label className="separator" />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Languages;
